import React, { lazy, Suspense } from 'react';
import './App.css';
import Directory from './Sections/directory/Directory';

// Lazy load the components
const Cern = lazy(() => import('./Sections/cern'));
const Moon = lazy(() => import('./Sections/moon'));
const Frames = lazy(() => import('./Sections/frames'));
const BlackHole = lazy(() => import('./Sections/hole'));
const Smoke = lazy(() => import('./Sections/smoke'));
const Balloons = lazy(() => import('./Sections/balloons'));
const Chicken = lazy(() => import('./Sections/chicken'));
const SVG_Flow = lazy(() => import('./Sections/SVG_Flow'));
const Teeth = lazy(() => import('./Sections/teeth'));
const Magic = lazy(() => import('./Sections/designs'));
const Prague = lazy(() => import('./Sections/prague'));
const Brush = lazy(() => import('./Sections/brush'));
const Intro = lazy(() => import('./Sections/intro'));
const Serres = lazy(() => import('./Sections/serres'));
const Egg = lazy(() => import('./Sections/egg'));
const Poems = lazy(() => import('./Sections/poems'));
const Yours = lazy(() => import('./Sections/yours'));
const Night = lazy(() => import('./Sections/night'));
const Googol = lazy(() => import('./Sections/googol'));
const Planes = lazy(() => import('./Sections/planes'));
const Veggies = lazy(() => import('./Sections/veggies'));
const Pinecones = lazy(() => import('./Sections/pinecones'));
const Contact = lazy(() => import('./Sections/contact'));
const Postcard = lazy(() => import('./Sections/postcard'));

function App() {
  return (
    <div className="App">
      <body style = {{overflow: 'hidden'}}>
        <Directory />
        <Suspense fallback={<div> <p> Loading... </p></div>}>
          <Intro />
          <Balloons />
          <BlackHole />
          <Egg />
          <Planes />
          <Smoke />
          <Night />
          <Pinecones />
          <Magic />
          <Poems />
          <Moon />
          <Veggies />
          <Frames />
          <Chicken />
          <Cern />
          <Teeth />
          <Postcard />
          <Googol />
          <Serres />
          <Prague />
          <SVG_Flow />
          <Yours />
          <Brush />
          <Contact />
        </Suspense>

      </body>
    </div>
  );
}

export default App;