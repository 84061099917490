
import React, { useState } from 'react';
import './Directory.css';

const Directory = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDirectory = () => {
    setIsOpen(!isOpen);
  };


  return (

    <div className={`directory-container ${isOpen ? 'open' : ''}`}>
      <div className="directory-toggle" onClick={toggleDirectory}>
        directory
      </div>
      
      {isOpen && (
        <div className="directory-content">
          <div className="overlay" onClick={toggleDirectory}></div>
          <div className="directory">
          <button className="close-button" onClick={toggleDirectory}>
              X
          </button>

            <ul>
              <li onClick={toggleDirectory}><a href="#prague">franz kafka museum</a></li>
              <li onClick={toggleDirectory}><a href="#veggies">some unsolicited advice about diet</a></li>
              <li onClick={toggleDirectory}><a href="#teeth">wisdom teeth</a></li>
              <li onClick={toggleDirectory}><a href="#brush">flying through oil fields at dusk</a></li>
              <li onClick={toggleDirectory}><a href="#dustin">first tragedy</a></li>
              <li onClick={toggleDirectory}><a href="#paper">the first black hole</a></li>
              <li onClick={toggleDirectory}><a href="#pinecones">notes on pinecones</a></li>
              <li onClick={toggleDirectory}><a href="#egg">humpty dumpty</a></li>
              <li onClick={toggleDirectory}><a href="#night">secrets of the garden</a></li>
              <li onClick={toggleDirectory}><a href="#planes">paper airplanes</a></li>
              <li onClick={toggleDirectory}><a href="#cern">CERN</a></li>
              <li onClick={toggleDirectory}><a href="#balloons">on measurement (the coldness of a ruler)</a></li>
              <li onClick={toggleDirectory}><a href="#yours">on distribution (the warmth of aggregation)</a></li>
              <li onClick={toggleDirectory}><a href="#googol">on magnitude (the terror of large numbers)</a></li>
              <li onClick={toggleDirectory}><a href="#photo_frames">parents driving me bananas</a></li>
              <li onClick={toggleDirectory}><a href="#smoke">smoke</a></li>
              <li onClick={toggleDirectory}><a href="#postcard">postcard from the morning to the evening</a></li>
              <li onClick={toggleDirectory}><a href="#serres">photo for serres</a></li>
              <li onClick={toggleDirectory}><a href="#contact">i met you at a bar and you directed me to this website?</a></li>
              <li onClick={toggleDirectory}><a href="#chickens">chickens</a></li>
              <li onClick={toggleDirectory}><a href="#magical thinking">GIFs for your crush</a></li>
              <li onClick={toggleDirectory}><a href="#listen">soft listening</a></li>
              <li onClick={toggleDirectory}><a href="#moon">view from my childhood skylight</a></li>
              <li onClick={toggleDirectory}><a href="#poems">lovers (lemon olive oil cake)</a></li>

            </ul>

          </div>
        </div>
      )}
    </div>
  );
};

export default Directory;